<template>
  <div>
    <div>
      <img src="https://water-1302249616.cos.ap-nanjing.myqcloud.com//2021-11-16/d96fbb445cd441a8ba6d396c68fc90ed-banner.png" alt style="width:100%">
    </div>
    <div class="tit-i">
      <img src="../assets/Group.png" alt>
      <h3>和仲案例</h3>
      <img src="../assets/Group Copy.png" alt>
      <h5>
        <span>SERVICE</span>
      </h5>
    </div>
    <!-- 和仲案例 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="srcList"
    />
    <div style="display:flex;width:1216px;margin:0 auto;flex-wrap: wrap;margin-bottom:60px">
      <div v-for="subitem in data" :key="subitem.id" class="bg">
        <el-image :src="subitem.imgUrl" class="case-bg-img" :lazy="true" />
        <div class="bgcolo" @click="handleShowPreview(subitem.imagesEntities)">
          <div class="font">
            <span class="oa">{{ subitem.title }}</span>
            <br>
            <span class="mode">
              {{ subitem.subTitle }}
            </span>
          </div>
          <img src="../assets/bf.png" class="case-play-btn" alt>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { listByParentId } from '../api/pc/home'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: { ElImageViewer },
  data() {
    return {
      data: [],
      showViewer: false,
      srcList: []
    }
  },
  created() {
    listByParentId(3)
      .then(res => {
        console.log('res', res)
        // res.data.item.parentDOS[1].subitemEntities.push(res.data.item.parentDOS[1].subitemEntities[0])
        this.data = res.data.items
      })
      .catch(e => {
        console.error(e)
      })
    // for (let i = 0; i < this.list.length; i++) {
    //   const item = this.list[i];
    //   console.log(item);
    // }
  },
  methods: {
    closeViewer(e) {
      this.showViewer = false
    },
    handleShowPreview(imgs) {
      console.log('handleShowPreview', imgs)
      if (!imgs || imgs.length === 0) {
        return
      }
      this.srcList = imgs.map(o => o.imgUrl)
      console.log('srcList', this.srcList)
      this.showViewer = true
    }
  }
}
</script>

<style  lang="scss" scoped>
.tit-i {
  padding: 20px 0;
  text-align: center;
  padding: 50px 0;
  line-height: 36px;
  overflow: hidden;
  h3 {
    font-size: 30px;
    color: #002;
    margin-bottom: 0px;
    display: inline-block;
  }
  h5 {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: Arial;
    margin-top: 10px;
    span {
      color: #e4392a;
    }
  }
  em {
    width: 45px;
    border-bottom: 1px solid #7f7f90;
    display: inline-block;
  }
}
.ul-icon-i {
  margin-bottom: 70px;
  .li {
    float: left;
    overflow: hidden;
    text-align: center;
    margin-top: -29px;
    margin-left: 62px;
  }
}
.bg {
  // background-image: url(../assets/rectangle2.png);
  position: relative;
  background-size: 100% 100%;
  display: inline-block;
  width: 380px;
  height: 282px;
  margin-left: 23px;
  margin-bottom: 22px;
  .case-bg-img {
    width: 380px;
    height: 282px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 23px;
    box-shadow: 0px 2px 8px 0px rgba(#409eff, 0.6);
  }
  .case-play-btn {
    width: 38px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding-top: 43px;
    cursor: pointer;
  }
  .bgcolo {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
    width: 377px;
    height: 282px;
    border-radius: 23px;
    text-align: center;
    position: relative;
    .font {
      padding-top: 100px;
      .oa {
        width: 260px;
        height: 42px;
        font-size: 30px;
        font-family: Helvetica;
        color: #ffffff;
        line-height: 36px;
      }
      .mode {
        width: 180px;
        height: 46px;
        font-size: 16px;
        font-family: Helvetica;
        color: #ffffff;
        line-height: 23px;
      }
    }
  }
}
</style>
